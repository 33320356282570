/* Reset Style */
@charset "utf-8";

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, address, em, img, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, nav, section, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

ol, ul {
  list-style: none; }

img {
  vertical-align: top;
  max-width: 100%;
  height: auto; }

input[type="text"]:focus {
  outline: none; }

button, input[type="submit"] {
  border: 0; }

button, input {
  line-height: normal;
  outline: none; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  font-family: 'Open Sans Condensed';
  font-weight: 400;
  *vertical-align: middle; }

input[type="text"],button, input[type="submit"],input[type="reset"],input[type="button"] {
  -webkit-appearance: none; }

button, input[type="submit"],input[type="reset"],input[type="button"] {
  cursor: pointer; }

input {
  &[disabled] {
    cursor: default; }
  &[type=checkbox], &[type=radio] {
    box-sizing: border-box;
    *height: 13px;
    padding: 0;
    *width: 13px; }
  &[type=search] {
    appearance: textfield;
    box-sizing: cnt-box;
    &::-webkit-search-decoration, &::-webkit-search-cancel-button {
      -webkit-appearance: none; } } }

a {
  color: $red; }

em, i {
  font-style: italic; }

strong, b {
  font-weight: 700; }

hr {
  border: none;
  border-bottom: 1px solid #ebebeb; }

/* Base styles */
html {
  * {
    box-sizing: border-box;
    text-size-adjust: 100%;
    font-size: 100%; } }

body {
  background: #fff;
  color: #414141;
  direction: ltr;
  font: 400 16px/1.2 'Open Sans', sans-serif, Arial, Helvetica, Verdana;
  letter-spacing: 0.025em; }

html, body {
  height: 100%; }

.align-center {
  text-align: center;
  .h1,h1 {
    display: inline-block;
    padding: 0 11%; } }

.h1,h1 {
  display: inline-block;
  margin-bottom: 38px;
  font-size: 38px;
  position: relative;
  padding: 0 70px;
  font-family: 'Lobster';
  color: #fff;
  &:before {
    content: '';
    display: block;
    top: 50%;
    left: 0;
    margin-top: -1px;
    position: absolute;
    border-top: 2px solid #fff;
    width: 65px; }
  &:after {
    content: '';
    display: block;
    top: 50%;
    right: 0;
    margin-top: -1px;
    position: absolute;
    border-top: 2px solid #fff;
    width: 65px; } }

.h2,h2 {
  margin: 0 auto 15px;
  padding-top: 10px;
  font-size: 26px;
  position: relative; }

.h3,h3 {
  margin-bottom: 15px;
  font-size: 23px;
  font-weight: 600; }

.h4,h4 {
  margin: 0 auto 15px;
  padding-top: 10px;
  font-size: 18px; }

.h5,h5 {
  padding-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 16px; }

.h6,h6 {
  padding-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 16px;
  color: #4c4c4c; }

.cl {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    line-height: 0; }
  &:after {
    clear: both; } }

.left {
  float: left; }

.right {
  float: right; }

.hide {
  display: none !important; }

.hideen {
  visibility: hidden;
  opacity: 0; }

.show {
  display: block !important; }

/*при использовании scale родительскому блоку добавить стиль backface-visibility: hidden*/
