.header {
  position: relative;
  padding: 54px 0 134px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  font-size: 18px;
  background: url(../images/header-bg.jpg) no-repeat -15px top /cover;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: -moz-linear-gradient(top,  rgba(45,46,52,1) 0%, rgba(45,46,52,0) 100%);
    background: -webkit-linear-gradient(top,  rgba(45,46,52,1) 0%,rgba(45,46,52,0) 100%);
    background: linear-gradient(to bottom,  rgba(45,46,52,1) 0%,rgba(45,46,52,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2d2e34', endColorstr='#002d2e34',GradientType=0 ); } }

.header__text {
  padding: 0 28px; }

.container {
  max-width: 975px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  z-index: 1; }

.content {
  margin-top: -90px; }

.top {
  padding: 26px 15px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  margin-bottom: 36px;
  background-color: $red;
  color: #fff;
  font-size: 14px;
  text-align: center;
  .h2,h2 {
    margin-bottom: 30px;
    padding-top: 0;
    font-size: 18px;
    font-weight: 700; } }

.top__item {
  float: left;
  width: 30%;
  &:nth-child(3) {
    width: 40%;
    .top__img {
      span {
        left: 21%; } } } }

.top__img {
  width: 81px;
  margin: 0 auto 16px;
  position: relative;
  span {
    position: absolute;
    left: 15%;
    top: 8px;
    font-size: 12px;
    font-weight: 600;
    color: #2d2d2d; } }

.conditions {
  margin-bottom: 25px; }

.request {
  margin-bottom: 25px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);
  background-color: $yellow;
  border-radius: 3px;
  overflow: hidden;
  .h2,h2 {
    padding: 20px;
    margin: 0;
    font-size: 30px;
    text-align: center;
    background-color: #464853;
    color: #fff; } }

.request__fields {
  padding: 32px 35px 36px; }

.field {
  width: 100%;
  height: 55px;
  margin: 6px 0;
  background-color: #ffeed3;
  padding: 0 16px;
  color: #2d2d2d;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 3px;
  font-size: 24px;
  &::-webkit-input-placeholder {
    color: #757575;
    opacity: 1; }
  &:-moz-placeholder {
    color: #757575;
    opacity: 1; }
  &::-moz-placeholder {
    color: #757575;
    opacity: 1; }
  &:-ms-input-placeholder {
    color: #757575;
    opacity: 1; }
  &:focus {
    background-color: #fff; } }

.request__note {
  margin: 6px 0 36px;
  font-size: 12px;
  color: #605f5f; }

.btn {
  width: 100%;
  padding: 12.5px;
  background-color: $red;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 3px;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff; }

.about-us {
  @extend .top;
  .h3,h3 {
    padding-top: 4px;
    margin-bottom: 34px;
    font-size: 18px; } }

.about-us__item {
  float: left;
  width: 33.333%;
  font-size: 14px;
  padding: 0 5px;
  strong {
    display: block;
    margin-bottom: 6px;
    font-size: 20px; } }

.about-us__img {
  margin-bottom: 16px;
  img {
    max-height: 44px; } }

.sanctions {
  .h3,h3 {
    text-align: center;
    padding-top: 0;
    margin-bottom: 20px; } }

.sanctions__desctop {
  display: none; }

.sanctions__mobile {
  font-size: 0;
  margin: 0 -15px; }

.sanctions__col {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 28px;
  font-size: 14px;
  color: #696969; }

.sanctions__item {
  border-radius: 3px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);
  background-color: #f0f0f0;
  overflow: hidden; }

.sanctions__ttl {
  padding: 12.5px;
  background-color: $red;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  text-align: center; }

.info__image {
  margin-bottom: 20px;
  text-align: center;
  img {
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5); } }

.sanctions__info {
  padding: 20px 15px;
  p {
    margin-bottom: 30px; }
  span {
    display: inline-block;
    border-bottom: 1px dashed $red;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 600; } }

.callback {
  position: relative;
  padding: 54px 0 40px;
  background: url(../images/footer-bg.jpg) no-repeat -195px top /cover;
  text-align: center;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: -moz-linear-gradient(top,  rgba(45,46,52,1) 0%, rgba(45,46,52,0) 100%);
    background: -webkit-linear-gradient(top,  rgba(45,46,52,1) 0%,rgba(45,46,52,0) 100%);
    background: linear-gradient(to bottom,  rgba(45,46,52,1) 0%,rgba(45,46,52,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2d2e34', endColorstr='#002d2e34',GradientType=0 ); }
  .btn {
    display: inline-block;
    vertical-align: middle;
    max-width: 305px;
    padding: 9px; } }

.callback__ttl {
  margin-bottom: 38px;
  padding: 0 5%;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #fff; }

.callback__form {
  text-align: center;
  font-size: 0; }

.callback__field-wrp {
  display: inline-block;
  width: 100%;
  max-width: 305px;
  vertical-align: middle;
  margin-bottom: 14px; }

.callback__field {
  width: 100%;
  height: 48px;
  color: #2d2d2d;
  padding: 0 16px;
  border-radius: 2px;
  border: 1px solid rgba(0,0,0,.1);
  font-size: 18px;
  &::-webkit-input-placeholder {
    color: #757575;
    opacity: 1; }
  &:-moz-placeholder {
    color: #757575;
    opacity: 1; }
  &::-moz-placeholder {
    color: #757575;
    opacity: 1; }
  &:-ms-input-placeholder {
    color: #757575;
    opacity: 1; } }

.reviews {
  text-align: center;
  margin-bottom: 20px;
  .h3,h3 {
    padding-top: 0;
    margin-bottom: 25px; } }

.avatar {
  display: inline-block;
  margin: 0 auto 20px;
  border-radius: 50%;
  border: 14px solid $pink;
  img {
    border-radius: 50%; } }

blockquote {
  display: block;
  padding: 21px 0 0 31px;
  background: url(../images/ico-blockquote.png) no-repeat left top;
  font-size: 15px;
  color: #3e3e3e;
  text-align: left;
  cite {
    display: block;
    margin-top: 20px;
    font-size: 16px;
    color: #2d2d2d;
    font-weight: 600;
    font-style: normal;
    text-align: right;
    span {
      color: $red; } } }

.author-info {
  display: block;
  text-align: right;
  font-size: 14px;
  color: #a3a3a3; }

.footer {
  font-size: 14px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  background-color: #464953;
  padding: 10px; }
