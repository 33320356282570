/*==========  Mobile First Method  ==========*/
/* Extra Small Devices, Phones*/
@media only screen and (min-width: 480px) {
  .avatar img {
    max-width: 200px; } }

@media only screen and (min-width: 500px) {
  .callback {
    background-position: center; } }

@media only screen and (min-width: 640px) {
  .callback__field-wrp {
    width: 33.333%;
    padding: 0 5px; } }

/* Small Devices, Tablets*/
@media only screen and (min-width: 768px) {
  .header {
    background-position: center;
    padding: 45px 0 125px; }
  .about-us {
    clear: both; }
  .request {
    width: 50%;
    float: left; }
  .conditions {
    width: 50%;
    float: left;
    padding-left: 45px; }
  .h1,h1 {
    font-size: 48px; }
  .header__text {
    font-size: 28px; }
  .top {
    .h2,h2 {
      font-size: 20px; } }
  .top__txt {
    font-size: 16px; }
  .top__item {
    padding: 0 20px;
    &:nth-child(3) {
      .top__img span {
        left: 18%; } } }
  .top__img {
    span {
      font-size: 16px;
      left: 13%;
      top: 6px; } }
  .request {
    .h2,h2 {
      font-size: 34px; } }
  .field {
    font-size: 28px; }
  .about-us__item strong {
    font-size: 24px; }
  .about-us {
    .h3,h3 {
      font-size: 24px; } }
  .h3, h3 {
    font-size: 24px; }
  .reviews {
    margin-bottom: 50px; }
  .sanctions {
    .h3, h3 {
      text-align: left; } }
  .sanctions__mobile {
    display: none; }
  .sanctions__desctop {
    display: table;
    border-collapse: collapse;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    overflow: hidden;
    th {
      vertical-align: middle;
      padding: 20px;
      background-color: $red;
      color: #fff;
      font-weight: 600;
      text-align: left;
      padding: 18px 20px;
      border-left: 1px solid #a43935; }
    td {
      background-color: #f0f0f0;
      font-size: 14px;
      color: #696969;
      padding: 18px 20px;
      vertical-align: middle;
      border: 1px solid #d7d7d7; } }
  .sanctions__max {
    width: 300px; }
  .info {
    .cnt {
      margin-right: 280px; } }
  .info__image {
    float: right; }
  .callback {
    padding: 54px 0 30px; }
  .callback__form {
    margin: 0 -5px 14px;
    display: table;
    width: 100%; }
  .callback__ttl {
    max-width: 600px;
    margin: 0 auto 38px; }
  .callback__field-wrp {
    display: table-cell;
    max-width: 100%; }
  .request__note {
    font-size: 14px; }
  .content {
    margin-top: -80px; } }

/* Medium Devices, Desktops*/
@media only screen and (min-width: 992px) {
  .top {
    font-size: 0; }
  .top__item {
    display: inline-block;
    vertical-align: middle; }
  .top__img {
    width: 81px;
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    margin-top: -12px; }
  .top__txt {
    display: inline-block;
    vertical-align: middle;
    width: 150px;
    padding-left: 22px;
    text-align: left; }
  .top__item {
    text-align: left; }
  .top__item:nth-child(3) {
    .top__txt {
      width: 200px; }
    .top__img {
      span {
        left: 13%; } } }
  .reviews__slider {
    padding: 0 96px; }
  .slider__item {
    font-size: 0; }
  .about-us__item {
    font-size: 16px;
    display: inline-block;
    vertical-align: middle; }
  .about-us__img {
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle; }
  .about-us__info {
    display: inline-block;
    vertical-align: middle;
    padding-left: 18px;
    text-align: left; }
  .avatar {
    width: 262px;
    float: left;
    img {
      max-width: 100%; } }
  .review {
    margin-left: 290px; }
  .request__fields {
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px; }
  .info {
    .cnt {
      margin-right: 330px; } }
  .request,.conditions {
    margin-bottom: 38px; }
  .callback {
    background-position: 0 -168px; }
  .callback__form {
    margin: 0 -15px 14px; }
  .callback__field-wrp {
    padding: 0 15px; }
  .slick-prev, .slick-next {
    top: 96px; } }
