/* Slider */

.slick-loading .slick-list {
  background: #fff url('../images/loader.gif') center center no-repeat; }

.slider__item {
  outline: none; }

.reviews__slider {
  padding: 0 48px;
  .slider__item {
    cursor: pointer;
    display: none;
    &:first-child {
      display: block; } } }

.slick-initialized {
  .slider__item {
    display: block; } }

.slider-load {
  display: none;
  position: absolute;
  width: 150px;
  height: 150px;
  top: 50%;
  left: 50%;
  margin: -75px 0 0 -75px;
  background: url(../images/loader.gif) no-repeat center; }

/* Arrows */
.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 78px;
  display: block;
  width: 33px;
  height: 61px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: url(../images/ico-arrows.png) no-repeat center; }

.slick-prev {
  background-position: left top;
  &:hover, &:focus {
    color: transparent;
    outline: none;
    background-position: left bottom; } }

.slick-next {
  background-position: right top;
  &:hover, &:focus {
    color: transparent;
    outline: none;
    background-position: right bottom; } }

.slick-prev {
  &:hover:before, &:focus:before {
    opacity: 1; } }

.slick-next {
  &:hover:before, &:focus:before {
    opacity: 1; } }

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before, .slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 0; }

[dir='rtl'] .slick-prev {
  right: 0;
  left: auto; }

.slick-prev:before {
  content: '←'; }

[dir='rtl'] .slick-prev:before {
  content: '→'; }

.slick-next {
  right: 0; }

[dir='rtl'] .slick-next {
  right: auto;
  left: 0; }

.slick-next:before {
  content: '→'; }

[dir='rtl'] .slick-next:before {
  content: '←'; }

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
      &:hover, &:focus {
        outline: none; }
      &:hover:before, &:focus:before {
        opacity: 1; }
      &:before {
        font-family: 'slick';
        font-size: 6px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: '•';
        text-align: center;
        opacity: .25;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; } }
    &.slick-active button:before {
      opacity: .75;
      color: black; } } }
