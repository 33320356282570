/*Content section*/
.table-wrp {
  overflow-y: auto; }

.cnt {
  margin: 0 auto;
  padding: 0 13px;
  position: relative;
  img[align="right"] {
    margin: 10px 0 10px 10px; }
  img[align="left"] {
    margin: 10px 10px 10px 0; }
  img[align="center"] {
    margin: 10px; }
  hr {
    border: none;
    border-bottom: 1px solid #000; }
  p {
    margin-bottom: 15px; }
  em, i {
    font-style: italic; }
  strong, b {
    font-weight: 700; }
  figure {
    clear: both;
    float: none;
    margin-bottom: 30px;
    text-align: center; }
  figcaption {
    padding-top: 10px;
    text-align: center;
    color: #5a5a5a;
    font-size: 15px; }
  ul {
    margin-bottom: 20px;
    display: inline-block; }
  ul li, ol li {
    line-height: 24px;
    text-align: left; }
  ul li {
    list-style: none;
    position: relative;
    padding-left: 25px;
    margin-bottom: 5px;
    &:before {
      content: "";
      position: absolute;
      top: 7px;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $red; } }
  ol {
    counter-reset: li;
    padding: 0;
    display: inline-block;
    margin-bottom: 20px;
    list-style: none;
    li {
      margin-bottom: 8px;
      position: relative;
      padding-left: 25px;
      &:before {
        content: counter(li)".";
        counter-increment: li;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        font-weight: 600; } } }
  table {
    margin: 0 0 25px;
    border-collapse: collapse; }
  td {
    padding: 10px;
    border: 1px solid #d6d6d6;
    color: #696969;
    font-size: 16px; }
  th {
    background-color: $red;
    border: 1px solid #d6d6d6;
    padding: 12px;
    color: #fff;
    font-weight: 700;
    font-size: 14px; } }

.tabs {
  margin-bottom: 30px;
  font-size: 0; }

.tab {
  border: 1px solid #dcdcdc;
  padding: 10px;
  display: none;
  font-size: 14px; }

.js-tabs {
  margin-bottom: -1px; }

.js-tabs__lnk {
  display: inline-block;
  padding: 10px;
  border: 1px solid #dcdcdc;
  font-size: 14px;
  color: #000;
  text-decoration: none;
  text-align: center; }

.js-tabs__lnk_active {
  border-bottom-color: #fff; }
